<template>
  <!-- src: https://loading.io/css/ -->
  <div class="lds-ring" aria-busy="true">
    <span class="sr-only">Loading...</span>
    <div v-for="i in 4" :key="i" :style="style"></div>
    <!-- 4 divs -->
  </div>
</template>

<script lang="ts" setup>
interface Props {
  blue?: boolean
  cobalt?: boolean
}

const props = defineProps<Props>()

const style = computed(() => {
  const color = props.blue ? '#0068fa' : props.cobalt ? '#4644DF' : '#fff'

  return {
    borderColor: `${color} transparent transparent transparent`,
  }
})
</script>

<style lang="scss" scoped>
.lds-ring {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.lds-ring div {
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
